<template>
  <div id="header" class="header">

    <div class="header-info">
      <div class="header-info-container container-kng">

        <div class="header-info-container-left">
          <div class="item-img">
            <img src="@/assets/img/geoloacation.png" alt="geoloacation.png">
          </div>
          <div class="item-text">
            г.Владивосток <br>
            ул. Шкотовская, 28
          </div>
        </div>

        <div class="header-info-container-middle">
          <div class="logo">
            <img src="@/assets/img/logo.png" alt="logo.png">
          </div>
          <div class="company">
            <div class="company_1">
              Производственная
            </div>
            <div class="company_2">
            компания
            </div>
          </div>
        </div>

        <div class="header-info-container-right">
          <div class="address-lg">
            <div class="item-text">
              г.Владивосток <br>
              ул. Шкотовская, 28
            </div>
          </div>
          <div class="phone">
            8 (423) 279-0-779
          </div>
          <a class="email" href="mailto:info@kngchem.com">
            info@kngchem.com
          </a>
        </div>
      </div>
    </div>

    <div class="header-navbar">
      <div class="header-navbar-container container-kng">
        <a href="#about-company" class="text">О компании</a>
        <a href="#equipment" class="text">О заводе</a>
        <a href="#products" class="text">Продукция</a>
        <a href="#quality" class="text">Контроль качества</a>
        <a href="#cooperation" class="text">Сотрудничество</a>
      </div>
    </div>

    <section class="top-nav">
      <div>
        <img style="width: 70px" src="@/assets/img/logo-footer.png" alt="logo.png">
      </div>
      <input id="menu-toggle" type="checkbox" />
      <label class='menu-button-container' for="menu-toggle">
        <div class='menu-button'></div>
      </label>
      <ul class="menu">
        <li><a href="#about-company">О компании</a></li>
        <li><a href="#equipment">О заводе</a></li>
        <li><a href="#products">Продукция</a></li>
        <li><a href="#quality">Контроль качества</a></li>
        <li><a href="#cooperation">Сотрудничество</a></li>
      </ul>
    </section>
  </div>
</template>


<script>
import '@/assets/sass/pages/header.sass'

export default {
  name: 'header-component',
  data() {
    return {
      modalNav: false,
    }
  },
  methods: {
    modalNavbar() {
      this.modalNav = !this.modalNav
    }
  }  
}
</script>