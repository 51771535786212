<template>

<div class="equipment" id="equipment">

<div class="equipment-container container-kng">
    <div class="equipment-container-title">
        ОБОРУДОВАНИЕ
    </div>
    
    <div class="equipment-container-body">
        <div class="block-equip" v-for="item in equipments.slice().reverse()" :key="item.id">
            <img :src="getImage('equipments', equipments.preview, '/files/facility/' + item.file)" :alt="item.file">
            <div class="block-equip-info">
                <div class="title">
                    {{ item.title }}
                </div>
                <div class="body">
                    <div v-html="item.description"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="equipment-container-body-lg">
      <carousel class="carousel-equipment"
        v-if="equipments"
        :autoplay="true"
        :nav="false"
        :margin="12"
        :loop="true"
        :autoWidth="true"
      >
        <div class="item" v-for="item in equipments.slice().reverse()" :key="item.id">
          <img :src="getImage('equipments', equipments.preview, '/files/facility/' + item.file)" :alt="item.file">
          <div class="item-info">
            <div class="item-info-title">
              {{item.title}}
            </div>
            <div class="item-info-body">
              <div v-html="item.description"></div>
            </div>
          </div>
        </div>
      </carousel>
    </div>
</div>

</div>

</template>

<script>
import '@/assets/sass/pages/equipment.sass'
import carousel from 'vue-owl-carousel'
import utils from '@/utils.js'


export default {
  name: 'equipment-component',
  components: {
    carousel,
  },
  data () {
    return {
      equipments: null,
    }
  },
  methods: {
    async getEquipments() {
        const { fetchData } = utils()
        const api = 'facility'
        let response = await fetchData('get', api)
        this.equipments = response?.data ?? this.equipments
    },
    getImage(type, name, path) {
        const { getStaticImage } = utils()
        return getStaticImage(type, name, path)
    },
  },
  created() {
    this.getEquipments()
  }
};

</script>