<template>

<div class="contract" id="contract">

  <div class="contract-container container-kng">
    <div class="title">
      Контрактное производство
    </div>
    <div class="body">
    
      <div class="body-left">
        Мы осуществляем полный технологический цикл производства продукции для наших заказчиков
      </div>

      <div class="body-right">
        <div class="item-block recipe">
          <div class="img">
            <img src="@/assets/img/contract/recipe.png" alt="recipe">
          </div>
          <div class="item-block-name">
            Разработка рецептур
          </div>
          <div class="item-block-description">
            Создаем оригинальные и действующие химические составы для продукции.
          </div>
        </div>

        <div class="item-block material">
          <div class="img">
            <img src="@/assets/img/contract/material.png" alt="material">
          </div>
          <div class="item-block-name">
            Подбор сырья и тары
          </div>
          <div class="item-block-description">
            Работаем с проверенными поставщиками сырья и тары из России, Кореи и Китая.
          </div>
        </div>

        <div class="item-block quality">
          <div class="img">
            <img src="@/assets/img/contract/quality.png" alt="quality">
          </div>
          <div class="item-block-name">
            Контроль качества
          </div>
          <div class="item-block-description">
            Наша система контроля качества исключает некачественное сырье и брак на производстве.
          </div>
        </div>

        <div class="item-block production">
          <div class="img">
            <img src="@/assets/img/contract/production.png" alt="production">
          </div>
          <div class="item-block-name">
            Производство
          </div>
          <div class="item-block-description">
            Изготавливаем необходимый объем продукции в сроки, установленные заказчиком.
          </div>
        </div>

        <div class="item-block packaging">
          <div class="img">
            <img src="@/assets/img/contract/packaging.png" alt="packaging">
          </div>
          <div class="item-block-name">
            Расфасовка
          </div>
          <div class="item-block-description">
            Упаковываем продукцию в гофрокартон партиями, нужными заказчику.
          </div>
        </div>

        <div class="item-block sending">
          <div class="img">
            <img src="@/assets/img/contract/sending.png" alt="sending">
          </div>
          <div class="item-block-name">
            Отправка заказчику
          </div>
          <div class="item-block-description">
            Работаем с логистическими компаниями, доставляющими продукцию по всей России.
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

</template>

<script>
import '@/assets/sass/pages/contract.sass'


export default {
  name: 'contract-component',
  data() {
    return {
        
    }
  }
};
</script>