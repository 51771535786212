<template>

<div class="products" id="products">

    <div class="products-title">
        <div class="products-title-container container-kng">
            Мы производим <span>широкий <br> ассортимент</span> продукции <br> высокого качества
        </div>
    </div>

    <div class="products-items">
        <div class="item" v-for="item in products" :key="item.id">
            <img :src="getImage('products', products.preview, '/files/product/' + item.file)" :alt="item.file">
            <div class="item-title">
                {{item.title}}
            </div>
        </div>
    </div>

    <div class="products-items-lg">
        <carousel class="carousel-product"
        v-if="products"
        :autoplay="true"
        :nav="false"
        :loop="false"
        :autoWidth="true"
        :responsive="{
            921:{
                dots: false
            }
        }"
        >
            <div class="item" v-for="item in products.slice().reverse()" :key="item.id">
                <img :src="getImage('products', products.preview, '/files/product/' + item.file)" :alt="item.file">
                <div class="item-title">
                    {{item.title}}
                </div>
            </div>
        </carousel>
    </div>

    <div class="products-brands container-kng">
        <div class="products-brands-title">
            БРЕНДЫ
        </div>

        <div class="products-brands-logo">
            <div class="products-brands-logo-item logo-kangaroo">
                <img src="@/assets/img/brand-logo-kangaroo.png" alt="brand-logo-kangaroo.png">
            </div>
            <div class="products-brands-logo-item logo-polly-rolly">
                <img src="@/assets/img/brand-logo-polly-rolly.png" alt="brand-logo-polly-rolly.png">
            </div>
            <div class="products-brands-logo-item logo-clingo">
                <img src="@/assets/img/brand-logo-clingo.png" alt="brand-logo-clingo.png">
            </div>
        </div>

        <div class="products-brands-body">
            <div class="kangaroo">
                <div class="img-title-md">
                    <img src="@/assets/img/brand-logo-kangaroo.png" alt="brand-logo-kangaroo.png">
                </div>
                <div class="body">
                    <div class="body-title">
                        <span>Kangaroo</span> – известная корейская автокосметика, продающаяся на рынке России уже более 25 лет.
                    </div>
                    <div class="body-ul">
                        <div class="body-ul-li">
                            <div class="label">
                                <img src="@/assets/img/brand-label.png" alt="brand-label.png">
                            </div>
                            <div class="text">
                                Продукция производится точно по корейской рецептуре;
                            </div>
                        </div>
                        <div class="body-ul-li">
                            <div class="label">
                                <img src="@/assets/img/brand-label.png" alt="brand-label.png">
                            </div>
                            <div class="text">
                                Процесс производства соответствует всем регламентам компании Kangaroo;
                            </div>
                        </div>
                        <div class="body-ul-li">
                            <div class="label">
                                <img src="@/assets/img/brand-label.png" alt="brand-label.png">
                            </div>
                            <div class="text">
                                Контроль качества на всех этапах изготовления продукции
                                совместно с корейским партнером
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom-image">
                    <img class="bottom-image-kangaroo" src="@/assets/img/brand-item-kangaroo.png" alt="brand-item-kangaroo.png">
                </div>
                <div class="bottom-alias">
                    <a href="https://kangaru.ru/">
                        www.kangaru.ru
                    </a>
                </div>
            </div>

            <div class="polly-rolly">
                <div class="body">
                    <div class="img-title-md">
                        <img src="@/assets/img/brand-logo-polly-rolly.png" alt="brand-logo-polly-rolly.png">
                    </div>
                    <div class="body-title">
                        <span>Polly Rolly</span> – новинка российского производства на рынке автокосметики. 
                    </div>
                    <div class="body-ul">
                        <div class="body-ul-li">
                            <div class="label">
                                <img src="@/assets/img/brand-label.png" alt="brand-label.png">
                            </div>
                            <div class="text">
                                Выверенный ассортимент, закрывающий все потребности автолюбителей;
                            </div>
                        </div>
                        <div class="body-ul-li">
                            <div class="label">
                                <img src="@/assets/img/brand-label.png" alt="brand-label.png">
                            </div>
                            <div class="text">
                                Компоненты российского производства;
                            </div>
                        </div>
                        <div class="body-ul-li">
                            <div class="label">
                                <img src="@/assets/img/brand-label.png" alt="brand-label.png">
                            </div>
                            <div class="text">
                                Рецептура, полностью разработанная  KNG Chemical
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom-image">
                    <img class="bottom-image-polly-rolly" src="@/assets/img/brand-item-polly-rolly.png" alt="brand-item-polly-rolly.png">
                </div>
                <div class="bottom-alias">
                    <a href="https://pollyrolly.ru/">
                        www.pollyrolly.ru
                    </a>
                </div>
            </div>

            <div class="clingo">
                <div class="body">
                    <div class="img-title-md">
                        <img src="@/assets/img/brand-logo-clingo.png" alt="brand-logo-clingo.png">
                    </div>
                    <div class="body-title">
                        <span>Clingo</span> – бренд химии с широким ассортиментом для бытовых нужд.
                    </div>
                    <div class="body-ul">
                        <div class="body-ul-li">
                            <div class="label">
                                <img src="@/assets/img/brand-label.png" alt="brand-label.png">
                            </div>
                            <div class="text">
                                Выверенный ассортимент, закрывающий все потребности потребителей;
                            </div>
                        </div>
                        <div class="body-ul-li">
                            <div class="label">
                                <img src="@/assets/img/brand-label.png" alt="brand-label.png">
                            </div>
                            <div class="text">
                                Компоненты российского производства;
                            </div>
                        </div>
                        <div class="body-ul-li">
                            <div class="label">
                                <img src="@/assets/img/brand-label.png" alt="brand-label.png">
                            </div>
                            <div class="text">
                                Рецептура, полностью разработанная  KNG Chemical
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom-image">
                    <img class="bottom-image-clingo" src="@/assets/img/brand-item-clingo.png" alt="brand-item-clingo.png">
                </div>
            </div>
        </div>
    </div>

    <div class="products-pricies" v-if="prices">
        <div class="products-pricies-container container-kng">
            <div class="title">
                Наши прайсы
            </div>
            <div class="body">
                Ознакомьтесь с полным ассортиментом и узнайте цены на него
            </div>
            <div class="buttons">
                <div 
                    v-for="(item, index) in prices"
                    :key="index"
                    class="button-item"
                >
                    <a class="button button-pink" :href="getHrefLink(item)" :download="item.file">
                        {{ item.title }}
                    </a>
                </div>
            </div>
        </div>
    </div>

</div>

</template>

<script>
import '@/assets/sass/pages/products.sass'
import carousel from 'vue-owl-carousel'
import utils from '@/utils.js'

export default {
  name: 'products-component',
  components: {
    carousel,
  },
  data() {
    return {
        products: null,
        prices: null
    }
  },
  methods: {
    async getEquipments() {
        const { fetchData } = utils()
        const api = 'product'
        let response = await fetchData('get', api)
        this.products = response?.data ?? this.products
    },
    async getPrices() {
        const { fetchData } = utils()
        const api = 'price'
        let response = await fetchData('get', api)
        this.prices = response?.data ?? this.prices
    },
    getImage(type, name, path) {
        const { getStaticImage } = utils()
        return getStaticImage(type, name, path)
    },
    getHrefLink(item) {
        const { getStaticImage } = utils()
        return !!item.link ? item.link : getStaticImage(null, null, item.path); 
    }
  },
  created() {
    this.getEquipments()
    this.getPrices()
  }
};
</script>