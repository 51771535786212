<template>
    <div class="map">
        <yandex-map
            :coords="coords"
            :zoom="16"
            :controls="['zoomControl', 'typeSelector', 'fullscreenControl']"
        >
            <ymap-marker
                :coords="coords"
                :icon="icon"
                marker-id="1"
                hint-content="Шкотовская улица, 28" 
            />
        </yandex-map>
    </div>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'

export default {
    components: { 
        yandexMap, 
        ymapMarker 
    },
    data() {
        return {
            coords: [
                43.247500, 
                132.023861
            ],
            icon: {
                content: 'Шкотовская улица, 28'
            },
        }
    }
}
</script>


<style>
.map {
    height: 50vh;
    min-height: 550px;
}

.ymap-container {
    height: 100%;
    width: 100%;
}
</style>