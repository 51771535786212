import axios from 'axios';

export default function () {
  const getStaticImage = (type, name, path = null) => {
    if (path) {
      return `${process.env.VUE_APP_BACKEND_URL}${path}`;
    }
    return `${process.env.VUE_APP_SERVICES_STATIC_URL}/${type}/${name}`;
  }

  const isObjectEmpty = (obj) => {
    for (var i in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, i)) {
        return false;
      }
    }
    return true;
  }

  const fetchData = async (action, target) => {
    let content = []
    try {
      await axios
        .get(`${process.env.VUE_APP_SERVICES_API_URL}/${action}/${target}`)
        .then((response) => {
          content = response.data
        });
    } catch (e) {
      console.log('We have the error', e)
    }
    return content
  };

  const getRequireJson = (filename) => {
    try {
      return require("./assets/data/" + filename)
    } catch (e) {
      console.log('We have the error', e)
    }
    return null
  };

  const sleep = async (timer, now = 0) => {
    return new Promise(function(resolve) {
        if (now < timer) {
            now += 100;
            setTimeout(function () { resolve(sleep(timer, now))}, 100);
        } else {
            resolve(true);
        }
    });
}

  return { getStaticImage, isObjectEmpty, fetchData, getRequireJson, sleep };
}
