<template>

<div class="quality" id="quality">
    <div class="quality-image">
        <img src="@/assets/img/quality/quality-main.png" alt="quality-main.png">
        <div class="quality-image-block">
            <div class="quality-image-block-text-1">
                Действует многоступенчатая <br> <span>система контроля качества</span><br> выпускаемой продукции
            </div>
            <div class="quality-image-block-text-2">
                Проверку в лаборатории проходит как поступаемое к нам сырье, так и готовая продукция
            </div>
        </div>

        <div class="quality-image-block-sm container-kng">
            <div class="quality-image-block-sm-text-1">
                Действует многоступенчатая <br> <span>система контроля качества</span><br> выпускаемой продукции
            </div>
            <div class="quality-image-block-sm-text-2">
                Проверку в лаборатории проходит как поступаемое к нам сырье, так и готовая продукция
            </div>
        </div>
    </div>

    <div class="quality-control container-kng">
        <div class="quality-control-title">
            СИСТЕМА КОНТРОЛЯ КАЧЕСТВА
        </div>
        
        <div class="quality-control-body">
            <div class="quality-control-body-block">
                <div class="quality-control-body-block-image">
                    <img src="@/assets/img/quality/quality-item-1.png" alt="quality-item-1.png">
                </div>
                <div class="quality-control-body-block-title">
                    I. Контроль сырья, тары и этикетки при поступлении
                </div>
                <div class="quality-control-body-block-info">
                    <div class="info-item">
                        <div class="info-item-img">
                            <img src="@/assets/img/quality/brand-label.png" alt="brand-label.png">
                        </div>
                        <div class="info-item-text">
                            Проверяем все новое сырье во внешней независимой лаборатории
                        </div>
                    </div>

                    <div class="info-item">
                        <div class="info-item-img">
                            <img src="@/assets/img/quality/brand-label.png" alt="brand-label.png">
                        </div>
                        <div class="info-item-text">
                            Проводим визуальный осмотр каждой поступаемой партии тары и упаковки 
                        </div>
                    </div>

                    <div class="info-item">
                        <div class="info-item-img">
                            <img src="@/assets/img/quality/brand-label.png" alt="brand-label.png">
                        </div>
                        <div class="info-item-text">
                            Соблюдаем условия хранения сырья, тары и этикетки
                        </div>
                    </div>
                </div>
            </div>


            <div class="quality-control-body-block">
                <div class="quality-control-body-block-image">
                    <img src="@/assets/img/quality/quality-item-2.png" alt="quality-item-2.png">
                </div>
                <div class="quality-control-body-block-title">
                    II. Контроль при производстве продукта
                </div>
                <div class="quality-control-body-block-info">
                    <div class="info-item">
                        <div class="info-item-img">
                            <img src="@/assets/img/quality/brand-label.png" alt="brand-label.png">
                        </div>
                        <div class="info-item-text">
                            Перед розливом проверяем каждую изготовленную партию по контрольным показателям: плотность, сухой остаток, рН и др.
                        </div>
                    </div>

                    <div class="info-item">
                        <div class="info-item-img">
                            <img src="@/assets/img/quality/brand-label.png" alt="brand-label.png">
                        </div>
                        <div class="info-item-text">
                            Взвешиваем каждую единицу продукции 
                        </div>
                    </div>

                    <div class="info-item">
                        <div class="info-item-img">
                            <img src="@/assets/img/quality/brand-label.png" alt="brand-label.png">
                        </div>
                        <div class="info-item-text">
                            Проверяем степень закрутки триггера и осутствие протечек
                        </div>
                    </div>

                    <div class="info-item">
                        <div class="info-item-img">
                            <img src="@/assets/img/quality/brand-label.png" alt="brand-label.png">
                        </div>
                        <div class="info-item-text">
                            Проверяем соответствие наклейки этикетки и датировки
                        </div>
                    </div>

                    <div class="info-item">
                        <div class="info-item-img">
                            <img src="@/assets/img/quality/brand-label.png" alt="brand-label.png">
                        </div>
                        <div class="info-item-text">
                            Отправляем контрольные образцы каждой партии на годовое хранение
                        </div>
                    </div>
                </div>
            </div>

            <div class="quality-control-body-block">
                <div class="quality-control-body-block-image">
                    <img src="@/assets/img/quality/quality-item-3.png" alt="quality-item-3.png">
                </div>
                <div class="quality-control-body-block-title">
                    III. Контроль готового продукта
                </div>
                <div class="quality-control-body-block-info">
                    <div class="info-item">
                        <div class="info-item-img">
                            <img src="@/assets/img/quality/brand-label.png" alt="brand-label.png">
                        </div>
                        <div class="info-item-text">
                            Регулярно отправляем контрольные образцы в независимую лабораторию на проверку соответствия рецептуре
                        </div>
                    </div>

                    <div class="info-item">
                        <div class="info-item-img">
                            <img src="@/assets/img/quality/brand-label.png" alt="brand-label.png">
                        </div>
                        <div class="info-item-text">
                            Проводим внешную оценку каждой единицы готовой продукции
                        </div>
                    </div>

                    <div class="info-item">
                        <div class="info-item-img">
                            <img src="@/assets/img/quality/brand-label.png" alt="brand-label.png">
                        </div>
                        <div class="info-item-text">
                            Проверяем укладку продукции в короб, его запечатку, датировку и укладку на паллет
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</template>


<script>
import '@/assets/sass/pages/quality.sass'


export default {
  name: 'quality-component',
  data() {
    return {
        
    }
  }
};
</script>