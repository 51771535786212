<template>
  <div id="app">
    <Header />  
    <AboutCompany />
    <Equipment />
    <Products />
    <Contract />
    <Quality />
    <Cooperation />
    <AboutUs />
    <YandexMap />
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import AboutCompany from './components/AboutCompany'
import Equipment from './components/Equipment'
import Products from './components/Products'
import Contract from './components/Contract'
import Quality from './components/Quality'
import Cooperation from './components/Cooperation'
import AboutUs from './components/AboutUs'
import YandexMap from './components/YandexMap'


export default {
  name: 'App',
  components: {
    Header,
    Footer,
    AboutCompany,
    Equipment,
    Products,
    Contract,
    Quality,
    Cooperation,
    AboutUs,
    YandexMap
},
  data() {
      return {
          modalItem: null,
      }
  },
  computed: {
  },
  watch: {
  },
}
</script>
