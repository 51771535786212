<template>

<div class="about-us" id="about-us">
    <div class="about-us-title">
        МАТЕРИАЛЫ О НАС
    </div>

    <div class="about-us-video">
        <div class="about-us-video-title container-kng">
            Видео
        </div>
        <div class="about-us-video-carousel">
            <carousel class="carousel-video"
                :nav="false"
                :margin="12"
                :loop="true"
                :autoWidth="true"
                v-if="videos"
            >
                <div class="carousel-video-item" v-for="item in videos" :key="item.id">
                    <div class="content-video">
                        <iframe class="video-width"
                        :src="item.link"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                    </div>
                    <div class="content-title">
                        {{item.title}}
                    </div>
                </div>
            </carousel>
        </div>
    </div>

    <div class="about-us-articles">
        <div class="about-us-articles-title container-kng">
            Статьи в СМИ
        </div>
        <div class="about-us-articles-carousel">
            <carousel class="carousel-article"
            :autoplay="true"
            :nav="false"
            :margin="12"
            :loop="true"
            :autoWidth="true"
            v-if="articles"
            >
                <a :href="item.link" class="carousel-article-item" v-for="item in articles" :key="item.id">
                    <div class="content-article">
                        <img class="article-width" :src="getImage('article', articles.preview, '/files/material/' + item.file)" :alt="item.file">
                    </div>
                    <div class="content-title-article">
                        {{item.title}}
                    </div>
                </a>
            </carousel>
        </div>
    </div>
</div>

</template>

<script>
import '@/assets/sass/pages/about-us.sass'
import carousel from 'vue-owl-carousel'
import utils from '@/utils.js'

export default {
  name: 'about-us-component',
  components: {
    carousel,
  },
  data() {
    return {
        videos: null,
        articles: null,
    }
  },
  methods: {
    async getMaterialsIsVideo() {
        const { fetchData } = utils()
        const api = 'material?is_video=true'
        let response = await fetchData('get', api)
        this.videos = response?.data ?? this.videos
    },
    async getMaterialsIsArticles() {
        const { fetchData } = utils()
        const api = 'material?is_video=false'
        let response = await fetchData('get', api)
        this.articles = response?.data ?? this.articles
    },
    getImage(type, name, path) {
        const { getStaticImage } = utils()
        return getStaticImage(type, name, path)
    },
  },
  created() {
    this.getMaterialsIsVideo()
    this.getMaterialsIsArticles()
  }
};

</script>