<template>
  <div id="footer" class="footer">
    <div class="footer-container container-kng">
      <div class="footer-container-left">
        <img src="@/assets/img/logo-footer.png" alt="logo-footer.png">
      </div>
      <div class="footer-container-right">
        <div class="phone">
          8 (423) 279-0-779
        </div>
        <a class="email" href="mailto:info@kngchem.com">
          info@kngchem.com
        </a>
      </div>
    </div>
  </div>
</template>


<script>
import '@/assets/sass/pages/footer.sass'

export default {
  name: 'footer-component',
  
};
</script>