<template>

<div class="cooperation" id="cooperation">

    <div class="cooperation-image">
        <img src="@/assets/img/cooperation/cooperation-main.png" alt="cooperation-main.png">
        <div class="block-info">
            <div class="block-info-title">
                Приглашаем к <span>сотрудничеству</span> <br> и совместному бизнесу
            </div>
            <ul class="block-info-body">
                <li class="block-info-body-item">
                    Работа напрямую с производителем без посредников
                </li>
                <li class="block-info-body-item">
                    Производство широкого спектра продукции
                </li>
                <li class="block-info-body-item">
                    Не зависим от ковидных ограничений и санкций
                </li>
            </ul>
        </div>

        <div class="block-info-md container-kng">
            <div class="block-info-md-title">
                Приглашаем к <span>сотрудничеству</span> <br> и совместному бизнесу
            </div>
            <ul class="block-info-md-body">
                <li class="block-info-md-body-item">
                    Работа напрямую с производителем без посредников
                </li>
                <li class="block-info-md-body-item">
                    Производство широкого спектра продукции
                </li>
                <li class="block-info-md-body-item">
                    Не зависим от ковидных ограничений и санкций
                </li>
            </ul>
        </div>
    </div>

    <div class="cooperation-partners container-kng">
        <div class="cooperation-partners-title">
            НАШИ ПАРТНЕРЫ
        </div>
        <div class="cooperation-partners-body">
            <div class="body-item uniqom">
                <div class="body-item-title-image">
                    <img style="height: 76.6px" src="@/assets/img/cooperation/partner-uniqom.png" alt="partner-uniqom.png">
                </div>
                <div class="body-item-info-company">
                    Uniqom – федеральный дистрибьютор автомобильных товаров, один из лидеров российского рынка.
                </div>
                <div class="body-item-info-text">
                    <div class="body-item-info-text-line">

                    </div>
                    <div class="body-item-info-text-item">
                        Инициатива данного партнера вкупе с потребительским спросом стала решающим фактором по открытию завода.
                    </div>
                </div>
                <div class="body-item-alias">
                    <a href="http://www.uniqom.ru">
                        www.uniqom.ru
                    </a>
                </div>
            </div>

            <div class="body-item kangaroo">
                <div class="body-item-title-image">
                    <img style="height: 111.66px" src="@/assets/img/cooperation/partner-kangaroo.png" alt="partner-kangaroo.png">
                </div>
                <div class="body-item-info-company">
                    Kangaroo – корейский гигант по производству химии с более чем 50 летней историей.
                </div>
                <div class="body-item-info-text">
                    <div class="body-item-info-text-line">

                    </div>
                    <div class="body-item-info-text-item">
                        Выверенные технологии и многолетний опыт данного партнера позволил быстро наладить наш производственный процесс.
                    </div>
                </div>
                <div class="body-item-alias">
                    <a href="http://www.kangaroo.co.kr/">
                        www.kangaroo.co.kr
                    </a>
                </div>
            </div>

            <div class="body-item erdc">
                <div class="body-item-title-image">
                    <img style="height: 75.01px" src="@/assets/img/cooperation/partner-erdc.png" alt="partner-erdc.png">
                </div>
                <div class="body-item-info-company">
                    Орган государственной поддержки, помогающий в реализации инвестиционных проектов на территории Свободный Порт Владивосток.
                </div>
                <div class="body-item-info-text">
                    <div class="body-item-info-text-line">

                    </div>
                    <div class="body-item-info-text-item">
                        За счет этой зоны мы получили налоговые льготы и особые административные преференции.
                    </div>
                </div>
                <div class="body-item-alias">
                    <a href="http://www.erdc.ru">
                        www.erdc.ru
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="cooperation-feedback">
        <div class="cooperation-feedback-container container-kng">
            <div class="cooperation-feedback-container-left">
                <div class="item-1">
                    Разместите у нас заказ на производство автокосметики, бытовой или промышленной химии под известным брендом или вашим СТМ
                </div>
                <div class="item-2">
                    <div class="item-2_1">
                        KNG Chemical
                    </div>
                    <div class="item-2_2">
                        г. Владивосток, ул. Шкотовская, 28
                    </div>
                </div>
                <div class="item-3">
                    8 (423) 279-0-779
                </div>
                <a class="item-4" href="mailto:info@kngchem.com">
                    info@kngchem.com
                </a>
            </div>
            <div class="cooperation-feedback-container-right">
                <div class="inputs">
                    <div :class="['inputs-item', 'input-1', {'input-error': inputs.name}]">
                        <input 
                            class="input-feedback"
                            type="text" 
                            placeholder="ФИО" 
                            id="name" 
                            v-model="model.name"
                        />
                    </div>
                    <div :class="['inputs-item', 'input-2', {'input-error': inputs.email}]">
                        <input 
                            class="input-feedback"
                            type="text" 
                            placeholder="Email" 
                            id="email" 
                            v-model="model.email"
                        />
                    </div>
                    <div :class="['inputs-item', 'input-3', {'input-error': inputs.number}]">
                        <input 
                            class="input-feedback"
                            type="text" 
                            placeholder="Телефон" 
                            id="number" 
                            v-model="model.number"
                        />
                    </div>
                    <div :class="['inputs-item', 'input-4', {'input-error': inputs.message}]">
                        <textarea 
                            class="input-feedback"
                            type="text" 
                            placeholder="Сообщение" 
                            id="message" 
                            v-model="model.message">
                        </textarea>
                    </div>
                </div>
                <div class="button-send">
                    <button class="button button-pink" @click="recaptcha" :disabled="!enabled"> 
                        <span uk-spinner v-if="!enabled"></span>
                        Отправить
                    </button>
                </div>

                <div class="feedback-message message-success" v-if="success">
                    <strong>
                        <span>Готово!</span>
                        Сообщение успешно отправлено.
                    </strong>
                </div>

                <div class="feedback-message message-error" v-if="!success && errors">
                    <strong>
                        <span>Произошла ошибка!</span>
                        {{ errors.message }}
                    </strong>
                </div>           
            </div>
        </div>
    </div>

</div>

</template>

<script>
import axios from 'axios';
import '@/assets/sass/pages/cooperation.sass';

export default {
    name: 'cooperation-component',
    data() {
        return {
            errors: null,
            success: null,
            model: {
                name: null,
                email: null,
                number: null,
                message: null,
            },
            enabled: true,
            duration: 200,
        }
    },
    computed: {
        inputs() {
            if (this.errors && this.errors.inputs) {
                return this.errors.inputs;
            }
            return {};
        },
    },
    methods: {
        async postFeedbackForm(data) {
            this.success = false;
            this.enabled = false;
            
            let duration = this.duration;
            let status = false;

            await axios
                .post( `${process.env.VUE_APP_SERVICES_API_URL}/post-form`, data)
                .then((response) => {
                
                    status = response.data.success;
                    
                    if (status) {
                        duration = 0;

                        for (let key in this.model) {
                            this.model[key] = null;
                        }
                    } else {
                        this.errors = response.data;
                    }
                })
                .catch(() => {});

            setTimeout(() => {
                this.enabled = true;
                this.success = status;
            }, duration);
        },
        async recaptcha() {
            let enabled = this.enabled;
            this.errors = null;
            for (let key in this.model) {
                if (this.model[key] == null) {       
                    let inputs = this.errors?.inputs ?? [];
                    inputs[key] = 'Пустое поле';

                    this.errors = {
                        message: 'Заполните все поля.',
                        inputs
                    };    
                    enabled = false;
                }
            }

            if (true === enabled) {
                await this.$recaptchaLoaded();
                this.model['token'] = await this.$recaptcha('login');
                this.postFeedbackForm(this.model);
            }
        },
  }
};
</script>