import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueYandexMetrika from 'vue-yandex-metrika'
import VueYoutube from 'vue-youtube'
import VueAnalytics from 'vue-analytics'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueRellax from 'vue-rellax'
import moment from 'moment'

import App from './App.vue'

import 'uikit'
import 'uikit/dist/css/uikit.min.css'
import "@/assets/style.sass"
import '@/assets/font/stylesheet.css'

Vue.config.productionTip = false;
Vue.prototype.$moment = moment
Vue.use(VueReCaptcha, { siteKey: `${process.env.VUE_APP_CAPTCHA_SITE_KEY}` })
Vue.use(VueRellax)
Vue.use(VueYoutube)
Vue.use(VueMeta)

const dbug = process.env.VUE_APP_ENV

if (process.env.VUE_APP_METRIC_YANDEX) {
  Vue.use(VueYandexMetrika, {
    id: process.env.VUE_APP_METRIC_YANDEX,
    env: dbug
  })
}

if (process.env.VUE_APP_METRIC_GOOGLE) {
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_METRIC_GOOGLE,
    disabled: dbug !== 'production'
  })
}

Vue.directive('click-outside', {
  inserted: (el, binding, vnode) => {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
  stopProp(event) {
    event.stopPropagation()
  },
});

Vue.directive('scroll', {
  inserted: function (el, binding) {
    let func = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', func)
      }
    }
    window.addEventListener('scroll', func)
  }
});

new Vue({
  render: (h) => h(App),
}).$mount('#app')