<template>

<div class="about-company" id="about-company">
    <div class="about-company-main-img">
        <img src="@/assets/img/main-about.png" alt="main-about.png">
        <div class="block-info">
            <div class="block-info-title">
                Производство автокосметики, бытовой и индустриальной химии во Владивостоке
            </div>
            <div class="block-info-body">
                На нашем заводе используется современное оборудование, которое позволяет выпускать 
                химическую продукцию в разных объемах и тарах: от бутылок в 200 мл до еврокубов в 1000 л.
            </div>
        </div>

        <div class="block-info-md">
            <div class="container-kng">
                <div class="block-info-md-title">
                    Производство автокосметики, бытовой и индустриальной химии во Владивостоке
                </div>
                <div class="block-info-md-body">
                    На нашем заводе используется современное оборудование, которое позволяет выпускать 
                    химическую продукцию в разных объемах и тарах: от бутылок в 200 мл до еврокубов в 1000 л.
                </div>
            </div>
        </div>
    </div>

    <div class="about-company-main-info" v-if="company">
        <div class="about-company-main-info-container container-kng">
            <div class="item-1">
                <div class="title">
                    <span class="span">{{company.area}}</span> м²
                </div>
                <div class="body">
                    Площадь завода
                </div>
            </div>
            <div class="item-2">
                <div class="title">
                    <span class="span">8</span> линий
                </div>
                <div class="body">
                    {{company.auto_lines}} автоматические <br>
                    {{company.semi_lines}} полуавтоматические
                </div>
            </div>
            <div class="item-3">
                <div class="title">
                    <span class="span">{{company.daily}}</span> ед.
                </div>
                <div class="body">
                    Выпускается продукции <br>
                    за 1 рабочую смену
                </div>
            </div>
            <div class="item-4">
                <div class="title">
                    <span class="span">{{company.people}}</span> человек
                </div>
                <div class="body">
                    Работает на заводе
                </div>
            </div>
        </div>
    </div>

    <div class="about-company-main-review">
        <div class="storage">
            <img src="@/assets/img/storage.png" alt="storage.png">
            <div class="block-bottom">
                Складские помещения
            </div>
        </div>
        <div class="laboratory">
            <img src="@/assets/img/laboratory.png" alt="laboratory.png">
            <div class="block-bottom">
                Лаборатория
            </div>
        </div>
        <div class="label">
            <img src="@/assets/img/label.png" alt="label.png">
            <div class="block-bottom">
                Комната хранения этикетки
            </div>
        </div>
    </div>
</div>

</template>

<script>
import '@/assets/sass/pages/about-company.sass'
import utils from '@/utils.js'

export default {
  name: 'about-company-component',
  data() {
    return {
        company: null,
    }
  },
  methods: {
    async getCompany() {
        const { fetchData } = utils()
        const api = 'company'
        let response = await fetchData('get', api)
        this.company = response?.data ?? this.company
    },
  },
  created() {
    this.getCompany()
  }
};

</script>